@tailwind base;
@tailwind components;
@tailwind utilities;

.all_children_full > *:not([data-testId="tooltip"]),
.all_children_full
  > *:not([data-testId="tooltip"])
  > *:not([data-testId="tooltip"]),
.all_children_full
  > *:not([data-testId="tooltip"])
  > *:not([data-testId="tooltip"])
  > *:not([data-testId="tooltip"]) {
  width: 100% !important;
  justify-content: flex-center !important;
}

.loading-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 15px;
}

.loading {
  position: relative;
  width: 100%;
  height: 15px;
  background: #f1f1f1;
  box-shadow: inset 0 0 5px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  z-index: 99999;
}

.loading:after {
  content: "";
  position: absolute;
  left: 0;
  width: 33%;
  height: 100%;
  animation: load 1s infinite, pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
  background: rgb(0, 172, 255);
  background: radial-gradient(
    circle,
    rgba(0, 172, 255, 1) 0%,
    rgba(126, 204, 242, 0.5) 70%,
    rgba(255, 255, 255, 0) 100%
  );
  animation-timing-function: linear;
}

@keyframes pulse {
  50% {
    opacity: 0.5;
  }
}

@keyframes load {
  0% {
    left: -50%;
  }
  25% {
    left: 0%;
  }
  50% {
    left: 50%;
  }
  75% {
    left: 75%;
  }
  100% {
    left: 100%;
  }
}

@layer components {
  .is-active {
    @apply bg-primary-600 text-white hover:bg-primary-900 hover:text-white;
  }

  .is-inactive {
    @apply hover:text-primary-600;
  }

  .button-base {
    @apply mx-2 inline-flex w-full justify-center rounded-md border border-transparent bg-primary-500 px-4 py-2 text-sm font-medium text-white shadow-sm hover:bg-primary-700 focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-slate-600;
  }

  .button-outline {
    @apply mx-2 inline-flex w-full justify-center rounded-md border border-primary-500 px-4 py-2 text-sm font-medium text-primary-500 shadow-sm transition-all hover:bg-primary-300 hover:text-white focus:outline-none focus:ring-2 focus:ring-primary-500 focus:ring-offset-2 disabled:cursor-not-allowed disabled:bg-slate-600;
  }
}

.no-margin * {
  margin: 0 !important;
}

.prose .ProseMirror,
.prose :where(strong):not(:where([class~="not-prose"] *)),
.prose :where(h3):not(:where([class~="not-prose"] *)) {
  color: black;
}

.ProseMirror {
  padding-left: 2px;
  border-bottom-left-radius: 0.75rem;
  border-bottom-right-radius: 0.75rem;
}

/* ----------------------------------------------
 * Generated by Animista on 2023-2-12 20:44:28
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation color-change-2x
 * ----------------------------------------
 */
@-webkit-keyframes color-change-2x {
  0% {
    background: #19dcea;
  }
  100% {
    background: #b22cff;
  }
}
@keyframes color-change-2x {
  0% {
    background: transparent;
  }
  100% {
    background: #029be5;
  }
}

.color-change-2x {
  -webkit-animation: color-change-2x 2s linear infinite alternate both;
  animation: color-change-2x 2s linear infinite alternate both;
}

body.locked {
  position: fixed;
  top: attr(data-scroll-y);
}

.public-DraftEditor-content {
  min-height: 100px;
  max-height: 400px;
  overflow-y: auto;
}
